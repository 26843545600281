import { Box } from "@storyofams/react-ui";
import { withStory, WithStoryProps } from "@storyofams/storyblok-toolkit";
import { GetStaticProps, NextPage } from "next";

import { Seo } from "~components";
import { globalStaticProps, mapBody, withLayout } from "~lib";
import { DynamicSections } from "~components/sections";
import { Background1, Background2 } from "~components/common/Background";
import storyblokSDK from "~lib/storyblok/client";

type HomePageProps = WithStoryProps;

const Home: NextPage<HomePageProps> = ({ story }) => {
  const { content } = story;

  return (
    <>
      <Background1 />
      <Background2 />
      <Seo story={story} />
      <Box overflow="hidden">
        <DynamicSections sections={content?.body} />
      </Box>
    </>
  );
};

export default withLayout(withStory(Home));

export const getStaticProps: GetStaticProps<HomePageProps> = globalStaticProps(
  async ({ locale }) => {
    let story;
    let notFound = false;

    try {
      const result = await storyblokSDK(3).home({
        slug: `${locale}/home`,
      });
      story = result.HomeItem;

      if (story?.content?.body) story = await mapBody(story, locale);
    } catch (e) {
      notFound = true;
    }

    try {
      const collections = await Promise.all(
        story?.content?.collections?.[0]?.collections?.map((id) => {
          return new Promise((resolve) => {
            resolve(
              storyblokSDK(2).collectionSlide({
                id,
                lang: locale,
              })
            );
          });
        })
      );
      story.content.collections[0].collections = collections;
    } catch (e) {
      console.error(e);
    }

    return {
      props: {
        story,
      },
      notFound: notFound || !story,
      revalidate: 60,
    };
  }
);
