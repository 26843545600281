import styled from "styled-components";
import { HomepageBg1, HomepageBg2 } from "../Icons";

const Background1 = styled(HomepageBg1)`
  position: absolute;
  top: 100%;
  width: min(100vw, 1024px);
  height: calc(min(100vw, 1024px) / 1440 * 948);

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 300%;
  }
`;

const Background2 = styled(HomepageBg2)`
  position: absolute;
  top: 300%;
  width: min(100vw, 1024px);
  height: calc(min(100vw, 1024px) / 1206 * 1447);

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    top: 100%;
  }
`;

export { Background1, Background2 };
